.react-tagsinput {
  border: 2px solid var(--ds-border-neutral, #dfe1e6);
  background-color: #fff;
  font-size: 0.875rem;
  padding: 8px 6px;
  padding-bottom: 2px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.react-tagsinput-input {
  width: 100%;
  display: block;
}

.react-tagsinput--focused {
  border-color: var(--ds-border-focus, #4c9aff);
  outline: none;
  box-shadow: none;
}

.react-tagsinput-tag {
  background-color: #8850bf;
  color: #fff;
  border: 1px solid #44285f;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.react-tagsinput-remove {
  text-decoration: none;
  color: #44285f;
  margin-left: 4px;
  font-weight: bold;
  font-size: 16px;
  transition: 0.2;
}

.react-tagsinput-remove:hover {
  color: #44285f;
  opacity: 0.8;
}
